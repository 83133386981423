<template>
  <section id="member" class="s-member" v-if="member.acf">
    <header
      class="member-header"
      :style="'background-image: url(' + member.acf.detail_background_photo.url + '); background-position: ' +  member.acf.background_image_pos_x + '% ' + member.acf.background_image_pos_y + '%;'"
    ></header>
    <div class="s-member-box">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4">
            <div class="s-member-profile-img">
              <img :src="member.acf.featured_image.url" class="img-fluid" data-no-retina>
              <div v-if="member.acf.instagram_link" class="i-instagram">
                <a :href="member.acf.instagram_link" target="_blank">
                  <font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-8 col-md-5">
            <div class="s-member-info">
              <h1 class="display-3 h3">{{ member.acf.first_name}} {{ member.acf.last_name }}</h1>
              <p>{{ member.acf.bio }}</p>
              <p v-html="member.acf.member_bio"></p>
              <h3>Favorite Locations to Ski:</h3>
              <p>
                <span
                  v-for="(location, index) in member.acf.favorite_skiing_locations"
                  :key="index"
                >
                  {{ location.location }}
                  <span
                    v-if="index != Object.keys(member.acf.favorite_skiing_locations).length - 1"
                  >,</span>
                </span>
              </p>
              <h3 v-if="member.acf.hometown">Hometown:</h3>
              <p>{{ member.acf.hometown }}</p>
              <div class="i-close">
                <a @click="$router.go(-1)" class="close-member">
                  <font-awesome-icon :icon="['fas', 'times-circle']"></font-awesome-icon>
                </a>
              </div>
            </div>
            <!--end s-smember-info-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </div>
    <!--end member box-->
    <FooterMain></FooterMain>
  </section>
</template>
<script>
import { MemberService } from "@/services/WpApi";
import FooterMain from "@/components/layout/FooterMain.vue";


export default {
  name: "SingleMember",
  components: {
    FooterMain
  },
  props: {
    slug: {
      type: String
    },
    memberData: {
      type: Object
    }
  },
  data() {
    return {
      member: {}
    };
  },
  computed: {
    pageTitle: function () {
      // `this` points to the vm instance
      return this.$route.params.slug.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
    }
  },
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  mounted() {
    this.memberData
      ? (this.member = this.memberData)
      : this.getMember(this.$route.params.slug);
  },
  watch: {
    $route(to, from) {
      const response = this.getMember(this.$route.params.slug);
    }
  },
  methods: {
    async getMember(slug) {
      const response = await MemberService.fetchMember(slug);
      this.member = response.data[0];
    }
  }
};
</script>